import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { environment } from '@sbd-doug-frontend/app/environments/environment';

@Component({
  selector: 'sbd-doug-frontend-chatty-cathy',
  templateUrl: 'cathy.component.html',
  standalone: true,
  imports: [CommonModule]
})

export class CathyComponent {
  showHeader = environment.shouldCathyBeChatty;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
